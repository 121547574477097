import { BaseRequest, BaseResponse, IReturn, Response } from '../carecloud/common.api';
import { Pager } from './pager';
import { UserItem } from './admin/userItem';
import { EntityLogEX, DynamoPager } from './admin/entityLog';
import { User } from './user.model';
// [Api("Get all users for one customer")]
// [Route("/user/list", "GET")]
export class GetUserListPageRequest extends BaseRequest implements IReturn<GetUserListResponse> {
  LogonName: string;
  UserNameEmailPhone: string;
  CustomerCodeName: string;
  ModuleRole: string;
  Status: string;
  Pager: Pager;

  createResponse() {
    return new GetUserListResponse();
  }
  getTypeName() {
    return 'GetUserListPageRequest';
  }
}

export class GetUserListResponse extends Response<UserItem[]> {
  Pager: Pager;
}

// [Route("/user/{AccountId}", "GET")]
export class GetUserRequest extends BaseRequest implements IReturn<GetUserResponse> {
  AccountId: number;
  createResponse() {
    return new GetUserResponse();
  }
  getTypeName() {
    return 'GetUserRequest';
  }
}

export class GetUserResponse extends Response<UserItem> {}

// [Route("/user/updateuser", "POST")]
export class UpdateUserRequest extends BaseRequest implements IReturn<UpdateUserResponse> {
  AccountUser: UserItem;
  createResponse() {
    return new UpdateUserResponse();
  }
  getTypeName() {
    return 'UpdateUserRequest';
  }
}

export class UpdateUserResponse extends Response<number> {}

// [Route("/user/updateuserroles", "POST")]
export class UpdateUserRolesRequest
  extends BaseRequest
  implements IReturn<UpdateUserRolesResponse>
{
  AccountUser: UserItem;
  IsAppendRole: boolean;

  createResponse() {
    return new UpdateUserRolesResponse();
  }
  getTypeName() {
    return 'UpdateUserRolesRequest';
  }
}

export class UpdateUserRolesResponse extends Response<number> {}

// [Route("/user/adminchangepassword", "POST")]
// [Route("/user/directresetpwd", "POST")]
export class AdminChangePasswordRequest extends BaseRequest implements IReturn<Response<boolean>> {
  RequestUserId: number;
  NewPassword: string;
  NeedEncrypted: boolean;
  createResponse() {
    return new Response<boolean>();
  }
  getTypeName() {
    return 'AdminChangePasswordRequest';
  }
}
/*[Route("/user/{RequestUserId}/logs/")]*/
export class GetUserLogRequest extends BaseRequest implements IReturn<Response<EntityLogEX>> {
  public RequestUserId: number;
  public StartDate: Date;
  public EndDate: Date;
  public Pager: DynamoPager;
  createResponse() {
    return new Response<EntityLogEX>();
  }
  getTypeName() {
    return 'GetUserLogRequest';
  }
}

/*[Route("/user/validate/logonname")]*/
export class ValidateUserLogonNameRequest
  extends BaseRequest
  implements IReturn<ValidateUserResponse>
{
  public LogonName: string;
  createResponse() {
    return new ValidateUserResponse();
  }
  getTypeName() {
    return 'ValidateUserLogonNameRequest';
  }
}
/*[Route("/user/validate/password")]*/
export class ValidateUserPasswordRequest
  extends BaseRequest
  implements IReturn<ValidateUserResponse>
{
  public RequestUserId: number;
  public Password: string;
  public NoEncrypted: boolean;
  createResponse() {
    return new ValidateUserResponse();
  }
  getTypeName() {
    return 'ValidateUserPasswordRequest';
  }
}
export class ValidateUserResponse extends Response<boolean> {}

/*[Route("/user/getuserbylogonname", "GET")]*/
export class GetUserByLogonNameRequest
  extends BaseRequest
  implements IReturn<GetUserByLogonNameResponse>
{
  public LogonName: string;
  createResponse() {
    return new GetUserByLogonNameResponse();
  }
  getTypeName() {
    return 'GetUserByLogonNameRequest';
  }
}
export class GetUserByLogonNameResponse extends Response<User> {}

/*[Route("/user/resetpasswordbyemail", "POST")]*/
export class ResetPasswordByEmailRequest
  extends BaseRequest
  implements IReturn<ResetPasswordByEmailResponse>
{
  public LogonName: string;
  public NewPassword: string;
  public NeedEncrypted: boolean;
  createResponse() {
    return new ResetPasswordByEmailResponse();
  }
  getTypeName() {
    return 'ResetPasswordByEmailRequest';
  }
}
export class ResetPasswordByEmailResponse extends Response<boolean> {}

/*[Route("/user/validate/logonnametoken","GET")]*/
export class ValidateUserLogonNameTokenRequest
  extends BaseRequest
  implements IReturn<Response<boolean>>
{
  public LogonName: string;
  public Token: string;
  createResponse() {
    return new Response<boolean>();
  }
  getTypeName() {
    return 'ValidateUserLogonNameTokenRequest';
  }
}
/*[Route("/user/resetpasswordbyemailtoken", "POST")]*/
export class ResetPasswordByEmailTokenRequest
  extends BaseRequest
  implements IReturn<Response<boolean>>
{
  public LogonName: string;
  public NewPassword: string;
  public NeedEncrypted: boolean;
  public Token: string;
  createResponse() {
    return new Response<boolean>();
  }
  getTypeName() {
    return 'ResetPasswordByEmailTokenRequest';
  }
}

/* start cookie rewrite forget password API start */

export class SendVerificationCodeResponse extends BaseResponse {
  public SendResult: number; // 0:发送成功 1:新的Email或Phone已经存在 2:帐号不存在 3:Email或Phone发送失败
}
/*[Route("/code/sendverificationcode", "POST")]*/
export class SendVerificationCodeRequest
  extends BaseRequest
  implements IReturn<SendVerificationCodeResponse>
{
  // tslint:disable-next-line:max-line-length
  public VerificationType: number; // 0:only verify user account 1:verify new account when change login name 2:verify account when forget password
  public AccountType: number; // 0:email 1:phone
  public PhoneCountryCode: number;
  public Account: string; // used when VerificationType is 1 or 2
  public UseSpecifiedText: boolean; // 使用指定的的内容
  public Specified_Key: string; // code key
  public Specified_EmailSubject: string;
  public Specified_MandrillTemplate: string;
  public Specified_SMS: string;
  public dic: any; // Dictionary<string, string>;
  createResponse() {
    return new SendVerificationCodeResponse();
  }
  getTypeName() {
    return 'SendVerificationCodeRequest';
  }
}

export class CheckVerificationCodeForForgetPasswordResponse extends BaseResponse {
  public CheckResult: number; // 0:通过 1:帐号不存在 2:认证码不存在在或者过期
}
/*[Route("/code/checkverificationcodeforforgetpassword", "POST")]*/
// tslint:disable-next-line:max-line-length
export class CheckVerificationCodeForForgetPasswordRequest
  extends BaseRequest
  implements IReturn<CheckVerificationCodeForForgetPasswordResponse>
{
  public VerificationCode: string;
  public AccountType: number; // 0:email 1:phone
  public Account: string;
  public PhoneCountryCode: number;
  public UseSpecifiedText: boolean; // 使用指定的的内容
  public Specified_Key: string; // code key
  createResponse() {
    return new CheckVerificationCodeForForgetPasswordResponse();
  }
  getTypeName() {
    return 'CheckVerificationCodeForForgetPasswordRequest';
  }
}

export class ResetPasswordResponse extends BaseResponse {
  public ResetResult: number;
}
/*[Route("/user/resetpassword", "POST")]*/
export class ResetPasswordRequest extends BaseRequest implements IReturn<ResetPasswordResponse> {
  public VerificationCode: string;
  public AccountType: number;
  public Account: string;
  public PhoneCountryCode: number;
  public NewPassword: string;
  createResponse() {
    return new ResetPasswordResponse();
  }
  getTypeName() {
    return 'ResetPasswordRequest';
  }
}
/* end cookie rewrite forget password API end */

/*[Route("/customer/updatecustomerlogo", "POST")]*/
export class UpdateCustomerLogoResponse extends BaseResponse {
  public PictureUrl: string;
  public PictureFile: string;
  public RequestCustomerId: number;
}
export class UpdateCustomerLogoRequest
  extends BaseRequest
  implements IReturn<UpdateCustomerLogoResponse>
{
  public RequestCustomerId: number;
  public Picture: string;
  public PictureName: string;
  createResponse() {
    return new UpdateCustomerLogoResponse();
  }
  getTypeName() {
    return 'UpdateCustomerLogoRequest';
  }
}

export class UserPictureResponse extends BaseResponse {
  public PictureUrl: string;
  public PictureFile: string;
  public RequestUserId: number;
}

/*[Route("/user/myprofile/Picture/update", "POST")]*/
export class SetUserPictureRequest extends BaseRequest implements IReturn<UserPictureResponse> {
  public RequestUserId: number;
  public UserPicture: string;
  public PictureName: string;
  createResponse() {
    return new UserPictureResponse();
  }
  getTypeName() {
    return 'SetUserPictureRequest';
  }
}

/*[Route("/user/myprofile/Picture", "GET")]
    [Route("/user/myprofile/Picture", "POST")]*/
export class GetUserPictureRequest extends BaseRequest implements IReturn<UserPictureResponse> {
  public RequestUserId: number;
  createResponse() {
    return new UserPictureResponse();
  }
  getTypeName() {
    return 'GetUserPictureRequest';
  }
}

export class VerifyAccountResponse extends BaseResponse {
  public VerifyResult: number; // 0:通过 1:认证码不存在 2:认证码已过期
}
/*[Route("/user/verifyaccount", "POST")]*/
export class VerifyAccountRequest extends BaseRequest implements IReturn<VerifyAccountResponse> {
  public VerificationCode: string;
  createResponse() {
    return new VerifyAccountResponse();
  }
  getTypeName() {
    return 'VerifyAccountRequest';
  }
}
/*[Route("/user/getuserfield", "GET")]*/
export class GetUserFieldRequest extends BaseRequest implements IReturn<Response<string>> {
  public FieldId: number;
  createResponse() {
    return new Response<string>();
  }
  getTypeName() {
    return 'GetUserFieldRequest';
  }
}

export class CareWaitBaseRequest extends BaseRequest {
  RequestCustomerId: number;
  RequestWorkspaceId: string;
}

// [Route("/user/linkage/{RequestUserId}/{EntityTypeCode}", "GET")]
export class GetUseLinkageRequest extends CareWaitBaseRequest implements IReturn<Response<any>> {
  RequestUserId: number;
  EntityTypeCode: string;
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetUseLinkageRequest';
  }
}

// [Route("/application/link", "POST")]
//1: User已Link过Family 2: Family已Link过
export class LinkParentRequest extends CareWaitBaseRequest implements IReturn<Response<number>> {
  InvitationCode: string;
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'LinkParentRequest';
  }
}

// [Route("/setting", "GET")]
export class GetWorkspaceSettingRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<any>>
{
  SettingKey: string;
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetWorkspaceSettingRequest';
  }
}
